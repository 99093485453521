import './Slider.css'; // Import CSS styles for the slider
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';  // Import Slick JS

const Slider = () => {
  const sliderRef = useRef(null); // Create a reference for the slider element

  useEffect(() => {
    const $slider = $(sliderRef.current);

    // Initialize the Slick slider
    if ($slider.length) {
      $slider.slick({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
    }

    // Cleanup the Slick instance on component unmount
    return () => {
      if ($slider.hasClass('slick-initialized')) {
        $slider.slick('unslick');
      }
    };
  }, []);  // Empty dependency array to run only once on mount

  return (
    	<section className="home-slider position-relative mb-30">
		            <div className="container">
		                <div className="home-slide-cover mt-30">
		                    <div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1" ref={sliderRef}>
		                    
		                        <div className="single-hero-slider single-animation-wrap">
		                        <img src="assets/imgs/slider/slider.png" alt="" />
		                            <div className="slider-content">
		                                <h1 className="display-2 mb-40">
		                                    Don’t miss amazing<br />
		                                    grocery deals
		                                </h1>
		                                <p className="mb-65">Sign up for the daily newsletter</p>
		                                <form className="form-subcriber d-flex">
		                                    <input type="email" placeholder="Your emaill address" />
		                                    <button className="btn" type="submit">Subscribe</button>
		                                </form>
		                            </div>
		                        </div>


		                        <div className="single-hero-slider single-animation-wrap">
		                        <img src="assets/imgs/slider/slider2.png" alt="" />
		                            <div className="slider-content">
		                                <h1 className="display-2 mb-40">
		                                    Fresh Vegetables<br />
		                                    Big discount
		                                </h1>
		                                <p className="mb-65">Save up to 50% off on your first order</p>
		                                <form className="form-subcriber d-flex">
		                                    <input type="email" placeholder="Your emaill address" />
		                                    <button className="btn" type="submit">Subscribe</button>
		                                </form>
		                            </div>
		                        </div>

		                    </div>
		                    <div className="slider-arrow hero-slider-1-arrow"></div>
		                </div>
		            </div>
		        </section>
  );
};

export default Slider;
